var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_vm.offerData2.data && _vm.offerData2.data[0].url ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://".concat(_vm.offerData2.data[0].navigateTo.replace('http://', '').replace('https://', ''))
    }
  }, [_c('b-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.offerData2.data[0].url.fileUrl
    }
  })], 1)]) : _vm._e(), _vm.offerData2.data && _vm.offerData2.data[1].url ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://".concat(_vm.offerData2.data[1].navigateTo.replace('http://', '').replace('https://', ''))
    }
  }, [_c('b-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.offerData2.data[1].url.fileUrl
    }
  })], 1)]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }