<template>
  <b-container>
    <b-row>
      <b-col md="6" v-if="offerData2.data && offerData2.data[0].url">
        <a
          :href="`https://${offerData2.data[0].navigateTo
            .replace('http://', '')
            .replace('https://', '')}`"
        >
          <b-img :src="offerData2.data[0].url.fileUrl" class="w-100"></b-img>
        </a>
      </b-col>
      <b-col md="6" v-if="offerData2.data && offerData2.data[1].url">
        <a
          :href="`https://${offerData2.data[1].navigateTo
            .replace('http://', '')
            .replace('https://', '')}`"
        >
          <b-img :src="offerData2.data[1].url.fileUrl" class="w-100"></b-img>
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Offer",
  components: {},
  data() {
    return {};
  },

  computed: {
    ...mapState({
      offerData2: ({ advertisement }) => advertisement.offer2,
    }),
  },

  methods: {
    ...mapActions({
      offer2: "advertisement/offerData2",
    }),
  },

  mounted() {
    this.offer2();
  },
};
</script>
